// services
import { getStoredSelectedModuleItemId } from "@/services/category/categoryModuleService";

// design
import { iconCategories } from "@/design/icon/iconConst";

// model
import { categoryAll } from "@/model/category/categoryModel";

// vuex
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
  mapMutations,
  mapGetters,
  mapActions
} = createNamespacedHelpers("category");

// store
import { SET_DRAWER_MINI } from "@/store/shared/mutationTypes/record/record-mutation-types";

// mixins
import { moduleItemsDrawerMixin } from "@/mixins/shared/base/drawer/moduleItemsDrawerMixin";

/**
 * categoryDrawerMixin
 */
export const categoryDrawerMixin = {
  mixins: [moduleItemsDrawerMixin],
  data() {
    return {
      categoryAll: categoryAll,
      iconCategories: iconCategories
    };
  },
  computed: {
    ...mapState({ categories: "categories" }),

    /**
     * Overwrite moduleItemsDrawerMixin computed
     */
    ...mapGetters(["drawerMini", "selectedModuleItemId"]),

    /**
     * Get Module Items (categories)
     * Overwrite moduleItemsDrawerMixin.items
     * @return {{id: number, name: string, formId: number, flags: number, categoryType: number, categoryTypeName: string}[]}
     */
    items() {
      if (this.searchDrawerItemName) {
        return this.categories?.filter(el => {
          return el.name
            .toLowerCase()
            .startsWith(this.searchDrawerItemName.toLowerCase());
        });
      }

      return this.categories ?? [];
    },

    /**
     * Overwrite Has Selectable Module Item
     * @return {boolean}
     */
    hasSelectableModuleItem() {
      return this.defaultSelectModuleItemId >= -1;
    },

    /**
     * Overwrite suggested Select (Category) Module Item Id
     * @return {Number|number}
     */
    defaultSelectModuleItemId() {
      return this.categoryAll?.id ?? -1;
    }
  },
  methods: {
    ...mapActions({ setCategories: "setCategories" }),

    /**
     * Overwrite moduleItemsDrawerMixin Mutations
     */
    ...mapMutations({ setDrawerMini: SET_DRAWER_MINI }),

    /**
     * Get Stored Selected Module Item Id (Category Id)
     * Overwrites moduleItemsDrawerMixin.getStoredSelectedModuleItemId
     * @returns {Number|number}
     */
    getStoredSelectedModuleItemId: () => getStoredSelectedModuleItemId(),

    /**
     * Determines whether Module Item exists by its id
     * @param {Number|number} id
     * @return {Boolean|boolean}
     */
    existsModuleItem(id) {
      return id === -1
        ? this.categoryAll?.id === -1
        : !!(this.items?.find(el => el.id === id) ?? undefined);
    },

    /**
     * list (Category) Items
     * @return {Promise<void>}
     */
    async listItems() {
      try {
        this.clearError();
        this.isLoading = true;
        await this.setCategories();
        await this.ensureSelectedItem();
      } catch (e) {
        this.handleError(e.toString());
      } finally {
        this.isLoading = false;
      }
    }
  }
};
