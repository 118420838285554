<template>
  <core-navigation-drawer
    :drawer-mini="drawerMini"
    :error="currentError"
    @alertVisibilityChanged="onAlertVisibilityChanged"
    @toggleMiniDrawer="toggleMiniDrawer"
  >
    <template v-slot:content>
      <!-- Search All Categories -->
      <base-nav-list>
        <base-menu-item :to="createModuleItemContentRoute(-1)">
          <template v-slot:iconName>
            {{ iconCategories }}
          </template>
          {{ categoryAll.name }}
        </base-menu-item>
      </base-nav-list>

      <v-divider></v-divider>

      <!-- Search Filter By Category Name -->
      <base-nav-list>
        <v-list-item dense>
          <search-text-field
            :loading="isLoading"
            v-model="searchDrawerItemName"
          ></search-text-field>
        </v-list-item>
      </base-nav-list>

      <!-- List Categories -->
      <category-list :categories="items"></category-list>
    </template>
  </core-navigation-drawer>
</template>

<script>
// mixins
import { categoryDrawerMixin } from "@/mixins/category/drawer/categoryDrawerMixin";

export default {
  name: "CategoryDrawer",
  mixins: [categoryDrawerMixin],
  components: {
    CoreNavigationDrawer: () =>
      import("@/components/shared/core/drawers/CoreNavigationDrawer"),
    CategoryList: () => import("@/components/category/CategoryList")
  },
  async created() {
    try {
      await this.listItems();
    } catch (e) {
      this.handleError(e);
    }
  }
};
</script>
